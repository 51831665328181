@import "./../../../assets/variables/variables.scss";

.wrapper {
  .header {
    width: 100%;
    display: flex;
    justify-content: end;

    .close {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }
  }

  .logo {
    background-image: url("./../../../assets/logos/Logo_img.png");
    background-size: 64px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 68px;
  }
  .title {
    font-family: "New York Small Bold";
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    margin-top: 16px;
    color: #2b3330;
  }

  .description {
    font-family: "New York Small";
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
    color: #2b3330;

    span {
      font-family: "New York Small Bold";
    }
  }

  .sub_title {
    font-family: "New York Small Bold";
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 32px;
    color: #2b3330;
  }

  .steps_title {
    font-family: "New York Small Bold";
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 64px;
    color: #2b3330;

    span {
      font-size: 21px;
    }
  }

  .img {
    width: 100%;
    max-width: 370px;
    display: block;
    margin: 16px auto 0 auto;
  }

  .button {
    margin: 14px auto 0 auto;
    background-color: #f3f4f4;
    border-radius: 4px;
    padding: 14px;
    width: 100%;
    font-family: "SF Pro Display";
    font-size: 14px;
    color: #2b3330;
    text-align: center;
    border: none;
    max-width: 240px;
    display: block;

    &:disabled {
      color: #b4b7b5;
    }
  }
}

.actions {
  margin-top: 38px;

  .buttonStay {
    background-color: #ff6d03;
    border-radius: 4px;
    padding: 18px;
    width: 100%;
    font-family: "SF Pro Display";
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    border: none;
    display: block;
  }

  .buttonProceed {
    background-color: transparent;
    border-radius: 4px;
    padding: 18px;
    width: 100%;
    font-family: "SF Pro Display";
    font-size: 14px;
    color: #2b3330;
    text-align: center;
    border: none;
    display: block;
  }
}
