.discountBlock {
  font-family: "SF-Pro-Text", "Arial", sans-serif;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  padding: 21px 21px 18px;
  position: relative;
  background-color: #cbdcb5;
  position: relative;

  &.isWhite {
    background-color: #f3f4f4;

    .save {
      background-color: #208357 !important;
      color: #fff;

      & :last-child {
        background-color: #208357 !important;
      }
    }
  }

  .save {
    display: block;
    position: absolute;
    top: 0;
    right: 7%;
    font-size: 14px;
    line-height: 1;
    color: #4b3800;
    background-color: #ffa621;
    text-transform: uppercase;
    padding: 9px 12px 6px 12px;
    right: 7%;

    & :last-child {
      position: absolute;
      width: 100%;
      top: 28px;
      right: 0px;
      height: 12px;
      background-color: #ffa621;
      clip-path: polygon(0% 0%, 100% 0%, 50% 70%);
    }
  }

  .totalCrossed {
    text-decoration: line-through;
    text-decoration-color: #ff4003;
    text-decoration-thickness: 2px;
  }

  .perDayBlock {
    margin-top: 26px;

    .pricePerDay {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;

      & > :first-child {
        font-family: "SF-Pro-Text";
        font-size: 20px;
      }

      & > :last-child {
        opacity: 1;
      }
    }
  }

  .totalBlock {
    display: flex;
    flex-direction: column;

    .planDuration {
      font-family: "SF-Pro-Text", "Arial", sans-serif;
      font-size: 1.2em;
      letter-spacing: 2px;
      line-height: 1.33;
      color: #1d4034;
      text-transform: capitalize;
      margin-bottom: 5px;
    }

    .total {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      word-wrap: normal;
      font-size: 14px;

      span {
        color: #1d4034;
      }
    }
  }
}

.selectedDiscountBlock {
  background-color: #1a6945;
  font-family: "SF-Pro-Text", "Arial", sans-serif;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  padding: 21px 21px 18px;
  position: relative;

  &.isWhite {
    background-color: #f3f4f4;
    border: 1px solid #208357;

    .pricePerDay {
      & > :first-child {
        font-family: "SF-Pro-Text";
        font-size: 20px;
        color: inherit !important;
      }

      & > :last-child {
        color: inherit !important;
      }
    }

    .planDuration {
      color: #1d4034 !important;
    }

    .total {
      span {
        color: #1d4034 !important;
      }
    }

    .save {
      background-color: #208357 !important;
      color: #fff !important;

      & :last-child {
        background-color: #208357 !important;
      }
    }
  }

  .save {
    display: block;
    position: absolute;
    top: 0;
    right: 7%;
    font-size: 14px;
    line-height: 1;
    color: #4b3800;
    background-color: #ffa621;
    text-transform: uppercase;
    padding: 9px 12px 6px 12px;
    right: 7%;

    & :last-child {
      position: absolute;
      width: 100%;
      top: 28px;
      right: 0px;
      height: 12px;
      background-color: #ffa621;
      clip-path: polygon(0% 0%, 100% 0%, 50% 70%);
    }
  }

  .totalCrossed {
    text-decoration: line-through;
    text-decoration-color: #ff4003;
    text-decoration-thickness: 2px;
  }

  .perDayBlock {
    margin-top: 26px;

    .pricePerDay {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;

      & > :first-child {
        font-family: "SF-Pro-Text";
        font-size: 20px;
        color: #fff;
      }

      & > :last-child {
        color: #fff;
      }
    }
  }

  .totalBlock {
    display: flex;
    flex-direction: column;

    .planDuration {
      font-family: "SF-Pro-Text", "Arial", sans-serif;
      font-size: 1.2em;
      letter-spacing: 2px;
      line-height: 1.33;
      color: #fff;
      text-transform: capitalize;
      margin-bottom: 5px;
    }

    .total {
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      word-wrap: normal;

      span {
        color: #fff;
      }
    }
  }
}
