.block {
  background-color: #ffffda;
  padding: clamp(20px, 5%, 30px) clamp(15px, 5%, 25px);
  border-radius: clamp(8px, 2vw, 16px);
  margin: 0 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  box-shadow: 0px 6px 11px -1px rgba(34, 60, 80, 0.2);

  @media (max-width: 330px) {
    padding: clamp(12px, 5%, 15px);
  }
}

.name {
  font-family: "New York Small";
  font-size: clamp(16px, 3vw, 22px);
  line-height: 1.2;
  text-align: left;
  color: #000;
  margin-bottom: 8px;
}

.ratingSatrs {
  display: flex;
  margin-bottom: 16px;

  & > img {
    width: 34px;
    height: 32px;

    @media (max-width: 420px) {
      width: 16px;
      height: 15px;
    }
  }
}

.describtion {
  font-family: "New York Small";
  font-size: clamp(16px, 3vw, 22px);
  line-height: 1.2;
  text-align: center;
}
