.container {
  padding: 5px 20px;
  background-color: #ffffe9;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #ffffe9;
  margin-bottom: 8px;
  cursor: pointer;
  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 16px;
  }
  main {
    display: flex;
  }
  &:has(:checked) {
    border: 2px solid #208357;
  }
}
.deal {
  font-family: Roboto;
  font-size: clamp(8px, 1.3vw, 12px);
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.04em;
  text-align: center;
  color: #565c59;
}
.badges {
  display: flex;
  gap: 4px;
}
.badge {
  padding: 2px 6px 2px 6px;
  gap: 10px;
  border-radius: 1px;
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: clamp(8px, 1.3vw, 12px);
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.04em;
  text-align: center;
}
.free {
  background-color: #f4f4c2;
  color: #1a6945;
}
.popular {
  background-color: #ffa621;
  color: #4b3800;
}

.content {
  width: 100%;
}
.heading {
  display: flex;
  justify-content: space-between;
  font-family: "SF Pro Display";
  font-size: clamp(16px, 2vw, 20px);
  font-weight: 600;
  line-height: 1.8;
  text-align: left;
}

.description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total {
  font-family: "SF Pro Display";
  font-size: clamp(16px, 2vw, 20px);
  font-weight: 300;
  line-height: 1;
  text-align: left;
  color: #2b3330;

  @media (max-width: 390px) {
    max-width: 120px;
  }
}

.prevPrice {
  position: relative;
  display: inline-block;
  padding-inline: 4px;
  margin-inline: 4px;

  @media (max-width: 390px) {
    margin-bottom: 4px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    display: block;
    border: 1px solid #ff6d03;
    border-radius: 1px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
.item {
  color: #2b333088;
}

.icon {
  width: clamp(20px, 2vw, 26px);
  height: clamp(20px, 2vw, 26px);
  min-width: clamp(20px, 2vw, 26px);
  min-height: clamp(20px, 2vw, 26px);
  border: 2px solid #dfe0df;
  border-radius: 50%;
  margin: 10px;
  &:has(:checked) {
    border: 2px solid #208357;
    position: relative;
    &::before {
      content: "";
      display: block;
      height: 30%;
      width: 50%;
      border-left: 2px solid #208357;
      border-bottom: 2px solid #208357;
      position: absolute;
      left: 15%;
      top: 45%;
      transform: rotate(-45deg);
      animation: mark 0.2s ease-in-out;
      transform-origin: top left;
    }
  }
  input[type="radio"] {
    display: none;
  }
}

@keyframes mark {
  0% {
    width: 0;
    height: 0;
  }
  40% {
    height: 30%;
  }
  100% {
    width: 50%;
  }
}
