.benefits {
  .benefitsBlock {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 24px;

    span {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 13px;
      min-width: 24px;
      min-height: 24px;
    }
  }
}
