.wrapper {
  width: 100%;
  max-width: 700px;
  margin: 24px auto 0 auto;
}

.button {
  width: 100%;
  min-width: 150px;
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141d19;
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 24px 8px;

  svg {
    width: 60px !important;
    height: 29px !important;
  }
}
