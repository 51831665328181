.wrapper {
  width: 100%;
  max-width: 700px;
  margin: 24px auto 0 auto;
  text-align: center;

  div {
    margin-bottom: 4px;
  }

  span {
    color: #696e6c;
    line-height: 1.4;
    letter-spacing: 0.1em;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    margin: 12px 0 0;
    text-transform: uppercase;
    font-family: "SF-Pro-Text";
  }
}
