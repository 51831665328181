section {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .montlyPlans {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .warning {
    text-transform: uppercase;
    font-family: "SF-Pro-Text";
    letter-spacing: 0.15em;
    line-height: 1.5;
    color: #1a6945;
    font-size: 7px;
    margin-bottom: 40px;
  }

  .paymentMethods {
    .paymentIcons {
      margin: 15px 0 35px 0;
      display: flex;
      justify-content: space-between;

      img {
        width: auto;
        height: 16px;
      }
    }
  }
}
