.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 24px;
  position: relative;

  .close {
    top: 0px;
    right: 12px;
    position: absolute;
    cursor: pointer;
    z-index: 1000;
  }

  .title {
    font-family: "Druk Cy Web", "Arial", sans-serif;
    font-size: 32px;
    line-height: 1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;
    color: #141d19;
  }

  .methods {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    margin: 24px auto 0 auto;

    span {
      width: 100%;
      height: 100px;
    }

    svg {
      border-radius: 4px;
      height: 66px;
      border: 2px solid #b4b7b5;
      padding: 15px 20px;
      cursor: pointer;
      width: 94%;
      height: 100px;
    }

    .active svg {
      border: 2px solid #ff6d03;
    }
  }

  .total {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > :first-child {
      font-family: "SF-Pro-Text", "Arial", sans-serif;
      line-height: 1.4;
      font-weight: 700;
      font-size: 14px;
    }

    & > :last-child {
      font-family: "SF-Pro-Text", "Arial", sans-serif;
      line-height: 1.4;
      font-size: 14px;
      font-weight: 700;
      color: #ff6d03;
    }
  }

  .paymentForm {
    display: none;

    &.active {
      display: block;
    }
  }

  .braintree {
    margin: 24px auto 0 auto;
    width: fit-content;
  }
}

.paymentFields {
  display: none;

  &.isActive {
    display: block;
  }
}
