.benefits {
  .benefitsBlock {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 24px;

    .benefit {
      .title {
        display: flex;
        align-items: center;
        font-family: "New York Small Bold";
      }

      .description {
        font-size: 14px;
        margin-left: 36px;
        line-height: 18px;
      }
    }

    svg {
      margin-right: 13px;
      min-width: 24px;
      min-height: 24px;
    }
  }
}
