@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  padding: 16px 0px;
  // position: sticky;
  top: 0px;

  .left {
    width: 14px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      height: 100%;
    }
  }

  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .steps {
      font-family: "Roboto", "Arial", sans-serif;
      font-size: 10px;
      color: #696e6c;
      font-weight: 700;
      text-transform: uppercase;
      unicode-bidi: isolate;
      text-size-adjust: 100%;
      font-style: normal;
      letter-spacing: 1px;
    }

    .question {
      display: flex;
      align-items: center;
      gap: 7px;
      margin-top: 4px;
      font-family: "Roboto", "Arial", sans-serif;
      font-size: 12px;
      color: #141d19;
      font-weight: 700;
      text-transform: uppercase;
      unicode-bidi: isolate;
      text-size-adjust: 100%;
      font-style: normal;
      letter-spacing: 1px;
    }

    .progress {
      width: 100%;
      margin-top: 6px;
    }
  }
}
