@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  padding-bottom: 120px;

  .image {
    margin-top: 24px;
    width: 100%;
  }

  .background {
    width: 100%;
    height: 120px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(
      180deg,
      rgba(203, 220, 181, 0) 0%,
      rgba(203, 220, 181, 1) 50%,
      rgba(203, 220, 181, 1) 100%
    );
    display: flex;
    justify-content: center;

    &.isWhite {
      background: transparent;
    }

    .btn {
      width: 100%;
      background-color: #2b3330;
      max-width: 692px;
      text-align: center;
      color: #ffffff;
      font-family: "SF-Pro-Text", "Arial", sans-serif;
      padding: 20px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      height: 54px;
      margin: 46px 16px 0px 16px;
      cursor: pointer;
    }
  }
}
