@import "./../../../assets/variables/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100dvh - 32px);
  padding-top: 24px;

  .header {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0px 16px;

    .close {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }
  }

  .logo {
    background-image: url("./../../../assets/logos/Logo_img.png");
    background-size: 64px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 68px;
  }

  .title {
    margin-top: 16px;
    font-family: "New York Small Bold";
    font-size: 24px;
    line-height: 26px;
    color: #ffffff;
    text-align: center;
  }

  .description {
    margin-top: 16px;
    font-family: "New York Small";
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
  }

  .cancelOffer {
    width: 100%;
    max-width: 388px;
    margin: auto;
  }

  .block {
    background-color: #2b3330;
    margin-top: -80px;
    margin-left: 24px;
    margin-right: 24px;
    border-radius: 8px;
    padding: 28px;
  }

  .priceBlock {
    width: 189px;
    height: 111px;
    background-image: url("./../../../assets/cancel/offer_background.png");
    margin: auto;

    .price {
      padding-top: 32px;
      text-align: center;
      font-family: "New York Small Bold";
      font-size: 36px;
      line-height: 36px;
      color: #2b3330;
    }

    .time {
      font-family: "New York Small Bold";
      font-size: 16px;
      line-height: 16px;
      color: #2b3330;
      text-align: center;
    }
  }

  .bootom {
    padding: 0 24px;
    margin-top: 24px;

    .buttonStay {
      background-color: #ff6d03;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      border: none;
      display: block;
    }

    .buttonProceed {
      background-color: transparent;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #2b3330;
      text-align: center;
      border: none;
      display: block;
    }
  }
}
