@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &.isWhite {
    .footer {
      background-color: #ecd5d5 !important;
      color: #730011 !important;

      svg {
        fill: #730011 !important;

        path {
          fill: #730011 !important;
        }
      }
    }
  }

  .question {
    width: calc(50% - 8px);
    aspect-ratio: 0.8;
    border-radius: 4px;
    overflow: hidden;

    .img {
      width: 100%;
      height: calc(100% - 40px);
      background-color: white;
      background-size: cover;
      background-position: center;
    }

    .footer {
      background-color: #191d1c;
      color: #ffffff;
      display: flex;
      padding: 12px;
      -webkit-tap-highlight-color: transparent;
      -moz-tap-highlight-color: transparent;
      -o-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      font-family:
        New York Small,
        "Times New Roman",
        serif;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      svg {
        fill: #ffffff;
        width: 12px;
        height: 12px;
        transform: rotate(180deg);

        path {
          fill: #ffffff;
        }
      }
    }
  }
}
