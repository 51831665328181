.header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;

  .burger {
    cursor: pointer;
    
  }
}
