.wrapper {
  margin-top: 24px;
}

.progress {
  position: relative;
  display: flex;
  align-items: center;
}

.start {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #ff6d03;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #fff;
    width: 18px;
    height: 18px;
  }
}

.end {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #d2d4d3;

  svg {
    fill: #d2d4d3;
  }
}

.bar {
  width: calc((100% - 60px) / 5);
  height: 9px;
  border-radius: 10px;
  background-color: #e3dcd8;
  margin: 0px 4px;

  &.active {
    background-color: #ff6d03;
  }
}

.gear {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: #696e6c;
    }
  }

  .tooltip {
    background-color: #d2d4d3;
    padding: 8px;
    font-family: "New York Small";
    font-size: 10px;
    color: #141d19;
    border-radius: 4px;
    text-wrap: nowrap;
    position: absolute;
    top: 36px;

    &:after {
      content: "";
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #d2d4d3;
      position: absolute;
      top: -5px;
      left: calc(50% - 5px);
    }
  }
}

.titles {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  list-style: 10px;
  color: #2b3330;
  font-family: "New York Small";
  width: calc(100% + 8px);
  margin-left: -4px;
}
