.wrapper {
  width: 100%;
  margin-top: 24px;

  .title {
    font-family: "Druk Cy Web";
    font-size: 32px;
    line-height: 32px;
    color: #2b3330;
  }

  .items {
    .item {
      display: flex;
      margin: 24px 0px;

      .image {
        min-width: 81px;
        min-height: 81px;
        background-size: contain;
        background-repeat: no-repeat;

        &.image1 {
          background-image: url("./../../../assets/images/presaleGuide_item1.png");
        }

        &.image2 {
          background-image: url("./../../../assets/images/presaleGuide_item2.png");
        }

        &.image3 {
          background-image: url("./../../../assets/images/presaleGuide_item3.png");
        }

        &.image4 {
          background-image: url("./../../../assets/images/presaleGuide_item4.png");
        }
      }

      .text {
        margin-left: 12px;

        .head {
          color: #ff6d03;
          font-family: "New York Small Bold";
          font-size: 16px;
          line-height: 20px;
        }

        .subtitle {
          color: #2b3330;
          font-family: "New York Small Bold";
          font-size: 16px;
          line-height: 20px;
        }

        .description {
          color: #2b3330;
          font-family: "New York Small";
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
