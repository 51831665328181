@import "./../../../assets/variables/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100dvh - 32px);

  .header {
    width: 100%;
    display: flex;
    justify-content: end;

    .close {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    margin-top: 12px;
    font-family: "New York Small Bold";
    font-size: 18px;
    line-height: 22px;
  }

  .questions {
    margin-top: 24px;

    .question {
      background-color: #f3f4f4;
      border-radius: 4px;
      margin-top: 4px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        font-family: "New York Small Bold";
        font-size: 14px;
        line-height: 16px;
        color: #2b3330;
      }

      .icon {
        opacity: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: #ff6d03;
        }
      }

      &.active {
        background-color: #ffffff;
        border: 1px solid #ff6d03;

        .label {
          color: #ff6d03;
        }

        .icon {
          opacity: 1;
        }
      }
    }
  }

  .bootom {
    .buttonStay {
      background-color: #ff6d03;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      border: none;
      display: block;
    }

    .buttonProceed {
      background-color: transparent;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #2b3330;
      text-align: center;
      border: none;
      display: block;
    }
  }
}
