.wrapper {
  font-family: "New York Small";
  background-color: #fff;
  padding: 12px 48px;
  border-radius: 8px;
  margin-top: 24px;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  color: #2b3330;

  b {
    font-family: "New York Small Bold";
  }
}
