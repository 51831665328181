.reviews {
  display: flex;
  flex-direction: column;
  background-color: #ffffda;
  padding: 16px;
  border-radius: 4px;

  &.isWhite {
    background-color: #f3f4f4 !important;
  }

  .text {
    font-family: "New York Small";
    font-weight: 400;
    font-size: 16px;
    margin: 0 0 16px 0;
    line-height: 24px;
  }

  .userName {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 14px;
    font-style: normal;
    color: #696e6c;
    margin-left: 16px;
  }

  .starsBlock {
    display: flex;
    align-items: center;
  }

  .stars {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
}
