.content {
  background-color: #f4f4c2;
  padding-top: 24px;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  section {
    width: 100%;
  }

  .carousel {
    width: calc(100% + 40px);
    max-width: 700px;
  }
}

.logo {
  margin: 0 auto;
  display: block;
  width: clamp(62px, 15%, 120px);
}

.offerDescriptionTitle {
  font-family: "SF-Pro-Text";
  font-size: clamp(13px, 3vw, 20px);
  letter-spacing: 0.1em;
  text-align: center;
  color: #565c59;
}

.offerDescriptionSubTitle {
  font-family: "Druk Cy Web";
  font-size: clamp(44px, 10vw, 60px);
  letter-spacing: 0.01em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: pre-line;
  max-width: 700px;
  margin: 0 auto;
  color: #2b3330;
}

.offerDescriptionDescription {
  font-family: "New York Small";
  font-size: clamp(16px, 3vw, 22px);
  letter-spacing: -0.02em;
  text-align: center;
  color: #565c59;
  width: 70%;
  margin: clamp(14px, 2vw, 20px) auto;
}

.divider {
  border: clamp(1px, 0.25vw, 2px) solid #ff6d03;
  border-radius: clamp(1px, 0.25vw, 2px);
  display: block;
  width: clamp(132px, 30%, 300px);
  margin: clamp(9px, 2vw, 20px) auto clamp(22px, 3vw, 30px);
}

.banner {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.bannerImg {
  width: calc(100% + 200px);
  object-fit: cover;
  display: block;
  margin: 0 -100px;
}

.whyItsImportant {
  padding-top: clamp(31px, 2vw, 40px);
  padding-bottom: clamp(41px, 2vw, 50px);
}

.whyItsImportantTitle {
  font-family: "New York Small";
  font-size: clamp(22px, 3vw, 30px);
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2b3330;
  max-width: 700px;
  width: 70%;
  margin: 0 auto;
  white-space: pre-line;
  padding-bottom: clamp(31px, 2vw, 40px);
}

.section {
  max-width: 700px;
  width: calc(100% + 20px);
  margin: 0 -10px;
  padding-bottom: clamp(41px, 2vw, 50px);
}

.benefitSection {
  display: flex;
  width: calc(100% + 20px);
  margin: 0 -10px;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0px;
}

.footer {
  background: linear-gradient(180deg, #ffffdf 20.61%, #c2c26d 105.69%);
  margin-top: -120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 30px 0px 30px;
}

.footerDivivder {
  border: clamp(1.5px, 2vw, 2.5px) solid #dbdb99;
  border-radius: 2.5px;
  height: 0;
  width: 80%;
  display: block;
  margin: 35px auto;
}

.footerTitle {
  font-family: New York Small;
  font-size: clamp(22px, 3vw, 30px);
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: center;
  white-space: pre-line;
}

.primery {
  width: 100%;
  background-color: #ff6d03;
  border-radius: 4px;
  border: none;
  font-family: "SF Pro Display";
  font-size: clamp(14px, 2vw, 20px);
  letter-spacing: 0.06em;
  text-align: center;
  padding: 20px;
  color: #fff;
  margin-bottom: 32px;
  cursor: pointer;
  text-transform: uppercase;
}
.secondary {
  background: none;
  font-family: "SF Pro Display";
  font-size: clamp(14px, 2vw, 20px);
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.06em;
  text-align: center;
  color: #696e6c;
  border: none;
  text-align: center;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
}
