.wrapper {
  width: 100%;
  margin-top: 48px;

  .title {
    font-family: "Druk Cy Web";
    font-size: 42px;
    line-height: 44px;
    color: #2b3330;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    white-space: pre-wrap;
  }

  .description {
    margin-top: 18px;
    font-family: "New York Small bold";
    font-size: 16px;
    line-height: 20px;
    color: #2b3330;
  }
}
