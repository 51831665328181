* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffe9;
  gap: 4px;
  padding: 10px 12px;
  border-radius: 8px;
  margin-bottom: 4px;
  border: 1px solid #ffffe9;

  .phoneContainer {
    position: relative;

    input {
      padding-left: 16px;
    }

    .plus {
      left: 0px;
      position: absolute;
      font-size: 18px;
      width: 14px;
      height: 12px;
      text-align: center;
      line-height: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:has(:focus) {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    .icon {
      display: flex;
    }
  }
  &:has(:disabled) {
    background-color: #f8f8d2;
    border: 1px solid #f8f8d2;

    .label {
      color: #d2d4d3;
    }
    .input {
      color: #d2d4d3;
    }
  }
  &:has([aria-invalid="true"]) {
    border: 1px solid #ff4003;
    .label {
      color: #ff4003;
    }
    .input {
      color: #ff4003;
    }
  }
}
.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.input {
  background: none;
  border: none;
  outline: none;
}

.label {
  font-family: "SF Pro Text";
  font-size: clamp(10px, 2vw, 18px);
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: left;
  color: #8e9391;
  text-transform: uppercase;
}
.input {
  caret-color: #ff6d03;
  font-size: clamp(16px, 2vw, 20px);

  &::placeholder {
    color: #d2d4d3;
    font-family: New York Small;
    font-size: clamp(16px, 2vw, 20px);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.icon {
  display: none;
  width: clamp(14px, 1.8vw, 18px);
  height: clamp(14px, 1.8vw, 18px);
  font-weight: 900;
  font-size: 0.5em;
  background: #565c59;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  border: none;
  position: absolute;
  right: 20px;
  span {
    line-height: 0.5;
    width: fit-content;
    display: block;
  }
}

.error {
  color: #ff4003;
  font-family: "New York Small";
  font-size: clamp(14px, 1.8vw, 18px);
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 4px;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}
