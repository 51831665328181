.wrapper {
  margin-top: 36px;

  .title {
    font-family: "Druk Cy Web";
    font-size: 33px;
    line-height: 32px;
    color: #2b3330;
  }
  .slider {
    margin: 24px auto 0 auto;
    max-width: 440px;
  }
}
