@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 26px;

  .title {
    width: 100%;
    font-family: "SF-Pro-Text";
    font-weight: 800;
    font-size: 22px;
    letter-spacing: 2.42px;
    text-transform: uppercase;
    text-align: center;
    color: #141d19;
    line-height: 1.2;

    &.isWhite {
      color: #730011;
    }
  }

  .description {
    margin-top: 36px;
    width: 100%;
    font-family: "New York Small";
    font-size: 18px;
    text-align: center;
    color: #2b3330;
    line-height: 24px;

    &.isWhite {
      color: #730011;
    }
  }
}
