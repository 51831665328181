.subTitle {
  font-family: "SF-Pro-Text", sans-serif;
  font-size: 19px;
  letter-spacing: 0.1em;
  font-weight: bold;
  line-height: 1.2;
  color: #141d19;
  text-transform: uppercase;
  text-align: center;
}
