@import "./../../../assets/variables/variables.scss";

.wrapper {
  .header {
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: "New York Small Bold";
      font-size: 24px;
      line-height: 24px;
    }

    .image {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background-image: url("./../../../assets/images/Logotip.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .table {
    margin-top: 16px;
    border: 1px solid #b4b7b5;
    border-radius: 4px;
    padding: 16px;

    .divider {
      width: 100%;
      background-color: #b4b7b5;
      height: 1px;
    }

    .row {
      padding: 16px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        font-family: "New York Small Bold";
        font-size: 18px;
        line-height: 22px;
      }

      .value {
        font-family: "New York Small";
        font-size: 18px;
        line-height: 22px;
      }
    }

    .autoRenewal {
      text-align: right;
      text-decoration: underline;
      font-family: "New York Small";
      font-size: 12px;
      width: 100%;
      padding: 16px 0px 0px 0px;
    }
  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000a8;
  top: 0;
  left: 0;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 64px 32px 32px 32px;
    background-color: #a1061d;
    min-width: 80%;

    .text {
      font-family: "New York Small Bold";
      text-align: center;
      color: #f3f4f4;
      font-size: 24px;
    }

    .button {
      background-color: #f3f4f4;
      border-radius: 4px;
      padding: 14px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #2b3330;
      margin-top: 14px;
      text-align: center;
      border: none;
    }

    .button_secondary {
      background-color: transparent;
      border-radius: 4px;
      padding: 12px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #fad7be;
      margin-top: 14px;
      text-align: center;
      border: none;
    }

    .close {
      position: absolute;
      right: 32px;
      top: 32px;

      svg {
        fill: #fff;
      }
    }
  }
}
