@import "./../../../assets/variables/variables.scss";

.login,
.verification {
  height: calc(100dvh - 32px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
  background-color: #2b3330;
  border-radius: 4px;
  padding: 18px;
  width: 100%;
  font-family: "SF Pro Display";
  font-size: 14px;
  color: #f3f4f4;
  margin-top: 14px;
  text-align: center;
  border: none;

  &:disabled {
    background-color: #f3f4f4;
    color: #b4b7b5;
  }
}
.button_secondary {
  background-color: #f3f4f4;
  border-radius: 4px;
  padding: 14px;
  width: 100%;
  font-family: "SF Pro Display";
  font-size: 14px;
  color: #2b3330;
  margin-top: 14px;
  text-align: center;
  border: none;

  &:disabled {
    color: #b4b7b5;
  }
}

.content {
  .title {
    font-family: "New York Small Bold";
    font-size: 18px;
    line-height: 22px;

    .link {
      text-decoration: underline;
    }
  }

  .description {
    margin-top: 16px;
    font-family: "New York Small";
    font-size: 16px;
    line-height: 20px;
    color: #696e6c;
  }

  .email {
    width: 100%;
    margin-top: 4px;

    input {
      width: 100%;
      border: none;
      border-top: 1px solid #d2d4d3;
      background-color: #f3f4f4;
      padding: 16px 14px;
      font-family: "New York Small";
      font-size: 16px;
      border-radius: 4px;
      outline: none;

      &[data-is-invalid="true"] {
        color: #ff4003;
      }
    }
  }

  .error {
    font-family: "New York Small";
    font-size: 16px;
    line-height: 20px;
    margin-top: 6px;
    color: #ff4003;
  }
}

.block {
  background-color: #fcfcfc;
  text-align: center;
  padding: 16px;

  .note {
    font-family: "New York Small Bold";
    font-size: 18px;
    line-height: 20px;
    color: #565c59;
  }

  .link {
    font-family: "New York Small";
    font-size: 16px;
    line-height: 20px;
    color: #565c59;
    margin-top: 4px;
    text-decoration: underline;
  }
}

.verification {
  .email {
    input {
      font-size: 22px;
      letter-spacing: 4px;
    }
  }
}

.link {
  text-decoration: underline;
}
