@import "./../../../assets/variables/variables.scss";

.wrapper {
  .header {
    width: 100%;
    display: flex;
    justify-content: end;

    .close {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;

      svg {
        fill: #ffffff;
      }
    }
  }

  .logo {
    background-image: url("./../../../assets/cancel/Logo.svg");
    background-size: 64px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 68px;
  }

  .congrads {
    font-family: "Druk Cy Web";
    text-align: center;
    width: 100%;
    color: #ffffff;
    font-size: 40px;
    letter-spacing: 2px;
    margin-top: 42px;
  }

  .img {
    margin-top: -84px;
    width: 100%;
  }

  .title {
    margin-top: 12px;
    font-family: "New York Small Bold";
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    padding: 0px 24px;
    color: #ffffff;
  }

  .content {
    border-radius: 8px 8px 0 0;
    background-color: #141d19;
  }

  .description {
    margin-top: 38px;
    font-family: "New York Small";
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    padding: 0px 24px;

    span {
      color: #ff6d03;
    }
  }

  .sub_title {
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "New York Small Bold";
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 0px 24px;
    color: #ffffff;
    position: absolute;
    min-width: 80%;
    padding: 24px;
  }

  .sub_title_bottom {
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "New York Small Bold";
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    padding: 0px 24px;
    color: #ffffff;
    position: absolute;
    min-width: 90%;
  }

  .block {
    position: relative;
    margin-top: 24px;
    width: 100%;
    min-width: 375px;
    height: 375px;
    background-image: url("./../../../assets/cancel/canceled.png");
    background-repeat: no-repeat;
    background-size: 375px;
    background-position: center;
    background-color: #141d19;
    border-radius: 8px 8px 0 0;
  }

  .buttons {
    margin-top: 42px;
    padding: 24px;
  }

  .buttonStay {
    background-color: #ff6d03;
    border-radius: 4px;
    padding: 18px;
    width: 100%;
    font-family: "SF Pro Display";
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    border: none;
    display: block;
    margin-top: 24px;
  }

  .buttonProceed {
    background-color: transparent;
    border-radius: 4px;
    padding: 18px;
    width: 100%;
    font-family: "SF Pro Display";
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    border: none;
    display: block;
  }
}
