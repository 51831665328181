.primary {
  width: calc(100% - 25px);
  max-width: 692px;
  border: none;
  cursor: pointer;
  color: white;
  box-shadow: -5px 4px 36px 25px #f4f4c2;
  background-color: #ff6d03;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: "SF-Pro-Text", "Arial", sans-serif;
  letter-spacing: 0.06em;
  font-size: 14px;
  min-height: 56px;
  position: fixed;
  bottom: 1%;
}

.secondary {
  width: 100%;
  background-color: #ff6d03;
  color: #fff;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;
  height: 56px;
  margin-top: 12px;
}

.disabled {
  width: 100%;
  background-color: #ff6d03;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 4px;
  opacity: 0.7;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  outline: 0;
  cursor: default;
  height: 56px;
  margin-top: 12px;
}
