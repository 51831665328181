.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;
  }

  .small {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 20px;
    height: 20px;
  }

  .spinner {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: spin 1s ease-in-out infinite;
  }

  .smallSpinner {
    border: 1px solid #ff6d03;
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
