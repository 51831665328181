@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.isWhite {
    circle {
      &.bg {
        stroke: #e5e5e5 !important;
      }
    }
  }

  .title {
    padding: 36px;
    text-align: center;
    font-size: 28px;
    text-align: center;
    color: #2b332f;
    font-family: "SF-Pro-Text";
    letter-spacing: 2px;
  }

  .list {
    margin: auto;
    margin-top: 48px;
    color: #2b332f;

    .item {
      margin: 16px;
      display: flex;
      align-items: center;

      .icon {
        min-width: 18px;
        width: 24px;
        height: 24px;
      }

      .text {
        font-family: "SF-Pro-Text";
        font-size: 18px;
        margin-left: 18px;
      }
    }
  }

  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .loading {
      margin-top: 24px;
      display: grid;
      place-items: center;
      width: 70%;
      position: relative;
    }

    .percents {
      font-size: 126px;
      color: #2b332f;
      font-family: "SF-Pro-Text";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 600px) {
        font-size: 86px;
      }

      @media (max-width: 400px) {
        font-size: 56px;
      }
    }
  }

  .circularProgress {
    --size: 350px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 25px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * 3.14159 * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    animation: progress-animation 5s linear 0s 1 forwards;

    circle {
      cx: var(--half-size);
      cy: var(--half-size);
      r: var(--radius);
      stroke-width: var(--stroke-width);
      fill: none;
      stroke-linecap: round;
    }

    circle {
      &.bg {
        stroke: #ffffda;
      }
    }

    circle {
      &.fg {
        transform: rotate(-90deg);
        transform-origin: var(--half-size) var(--half-size);
        stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
        transition: stroke-dasharray 3s linear 0s;
        stroke: #2b332f;
      }
    }
  }
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@keyframes progress-animation {
  from {
    --progress: 0;
  }
  to {
    --progress: 100;
  }
}
