.wrapper {
  display: none;
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;

  &.active {
    display: block;
  }

  .container {
    margin: 0 auto;
    padding: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    max-width: 724px;

    .tryAgian {
      font-weight: 700;
      color: #8e9391;
      font-size: 16px;
    }

    .tryDifferentBtn {
      background-color: #ff6d03;
      border: 1px solid #ff6d03;
      border-radius: 4px;
      color: white;
      text-transform: uppercase;
      outline: none;
      width: 100%;
      font-family: "SF-Pro-Text";
      letter-spacing: 0.06em;
      font-size: 14px;
      min-height: 56px;
      padding: 10px;
    }

    .or {
      display: flex;
      gap: 10px;
      align-items: center;
      font-weight: 700;
    }

    .or::before,
    .or::after {
      content: "";
      height: 2px;
      width: 37px;
      background-color: #141d19;
    }

    .problems {
      padding: 20px 40px;
      background-color: #f3f4f4;
      color: #565c59;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 4px;
      gap: 19px;
      text-wrap: balance;
      margin: 0 auto;

      p {
        font-family: "SF-Pro-Text";
        font-size: 16px;
        text-align: center;
      }
    }

    .osBtns {
      display: flex;
      flex-direction: column;
      gap: 19px;
    }
  }
}
