@import "./../../../assets/variables/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100dvh - 32px);

  .header {
    width: 100%;
    display: flex;
    justify-content: end;

    .close {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }
  }

  .logo {
    background-image: url("./../../../assets/logos/Logo_img.png");
    background-size: 64px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 68px;
  }

  .field {
    margin-top: 24px;

    textarea {
      width: 100%;
      border: none;
      border-top: 1px solid #d2d4d3;
      background-color: #f3f4f4;
      padding: 16px 14px;
      font-family: "New York Small";
      font-size: 16px;
      border-radius: 4px;
      outline: none;
      resize: none;
      height: 170px;
    }
  }

  .title {
    margin-top: 12px;
    font-family: "New York Small Bold";
    font-size: 24px;
    line-height: 26px;
  }

  .bootom {
    .buttonStay {
      background-color: #ff6d03;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      border: none;
      display: block;
    }

    .buttonProceed {
      background-color: transparent;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #2b3330;
      text-align: center;
      border: none;
      display: block;
    }
  }
}
