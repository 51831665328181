@import "./../../../assets/variables/variables.scss";

.wrapper {
  .banner {
    position: relative;
    width: 100%;
    height: 295px;
    background-image: url("./../../../assets/cancel/banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .bannerLogo {
      position: absolute;
      left: calc(50% - 34px);
      bottom: 22px;
      width: 68px;
      height: 32px;
      background-image: url("./../../../assets/cancel/Logo.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .content {
    width: 100%;
    background-color: white;
    margin-top: -12px;
    border-radius: 8px;
    padding: 32px 16px;

    .title {
      font-family: "New York Small Bold";
      font-size: 24px;
      line-height: 27px;
      text-align: center;
      margin-top: 16px;
      color: #2b3330;
    }

    .description {
      font-family: "New York Small Bold";
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-top: 16px;
      color: #2b3330;
    }

    .block {
      font-family: "New York Small Bold";
      font-size: 15px;
      line-height: 17px;
      color: #ffffff;
      background-color: #2b3330;
      padding: 16px;
      border-radius: 4px;
      text-align: center;
      margin-top: 16px;

      span {
        color: #ff6d03;
      }
    }

    .sub_title {
      font-family: "New York Small Bold";
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      margin-top: 32px;
      color: #2b3330;
    }

    .steps_title {
      font-family: "New York Small Bold";
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-top: 64px;
      color: #2b3330;

      span {
        font-size: 21px;
      }
    }

    .img {
      width: 100%;
      max-width: 370px;
      display: block;
      margin: 16px auto 0 auto;
    }

    .button {
      margin: 14px auto 0 auto;
      background-color: #f3f4f4;
      border-radius: 4px;
      padding: 14px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #2b3330;
      text-align: center;
      border: none;
      max-width: 240px;
      display: block;

      &:disabled {
        color: #b4b7b5;
      }
    }
  }

  .points {
    margin-top: 24px;
    .point {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #f3f4f4;
      border-radius: 4px;
      margin-top: 4px;
      padding: 12px;

      .check {
        width: 24px;
        height: 24px;
        margin-right: 12px;

        svg {
          fill: #ff6d03;
        }
      }

      .text {
        font-family: "New York Small";
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  .actions {
    margin-top: 38px;

    .buttonStay {
      background-color: #ff6d03;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      border: none;
      display: block;
    }

    .buttonProceed {
      background-color: transparent;
      border-radius: 4px;
      padding: 18px;
      width: 100%;
      font-family: "SF Pro Display";
      font-size: 14px;
      color: #2b3330;
      text-align: center;
      border: none;
      display: block;
    }
  }
}
