.wrapper {
  text-align: center;
  padding: 0px 16px;

  .title {
    font-family: "Druk Cy Web";
    font-size: 33px;
    line-height: 30px;
    color: #2b3330;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-top: 24px;
  }

  .description {
    margin-top: 24px;
    font-family: "New York Small";
    font-size: 18px;
    line-height: 22px;

    b {
      font-family: "New York Small Bold";
    }
  }

  .banner {
    margin-top: 24px;
    width: 100%;
    max-width: 350px;
  }

  .note {
    margin-top: 12px;
    font-family: "New York Small";
    color: #696e6c;
    font-size: 12px;
    line-height: 14px;
  }

  .block {
    padding: 20px;
    background-color: #f3f4f4;
    margin-top: 24px;
    border-radius: 4px;
    font-family: "New York Small";
    font-size: 18px;
    line-height: 22px;

    b {
      font-family: "New York Small Bold";
    }
  }

  .btn {
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .close {
    top: 24px;
    right: 24px;
    position: absolute;
    cursor: pointer;
    z-index: 1000;
  }
}
