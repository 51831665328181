.wrapper {
  width: 100%;
  margin-top: 48px;

  .title {
    font-family: "SF Pro Display";
    font-size: 18px;
    line-height: 24px;
    color: #2b3330;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: #ff6d03;
  }

  .description {
    font-family: "SF Pro Display";
    font-size: 18px;
    line-height: 24px;
    color: #2b3330;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
  }
}
