.form {
  width: 100%;
  max-width: 1024px;

  .braintree-custom-input {
    border: none;
    outline: none;
    transition: border-color 100ms;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
}

.recaptcha {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recaptchaMessage {
  color: #ca2a2a;
  margin-top: 12px;
}

.pay {
  width: 100%;
  background-color: #ff6d03;
  color: #fff;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;
  height: 56px;
  margin-top: 12px;
  margin-bottom: 24px;
  max-width: 1024px;
}
