.wrapper {
  width: 100%;
  margin-top: 48px;

  .heading {
    font-family: "SF Pro Display";
    font-size: 18px;
    line-height: 54px;
    color: #565c59;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    text-align: center;
    font-weight: 600;
  }

  .title {
    margin-top: 24px;
    font-family: "New York Small Bold";
    font-size: 24px;
    text-align: center;
    color: #2b3330;

    i {
      font-style: italic;
    }
  }

  .description {
    margin-top: 18px;
    font-family: "New York Small Bold";
    font-size: 18px;
    color: #2b3330;
  }

  .stats {
    width: 100%;
    height: 205px;
    background-image: url("./../../../assets/images/stats.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 24px;
  }

  .smallTitle {
    margin-top: 24px;
    font-family: "SF Pro Display";
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: #2b3330;
  }

  .paragraph {
    margin-top: 18px;
    font-family: "New York Small";
    font-size: 16px;
    line-height: 20px;
    color: #2b3330;

    b {
      font-family: "New York Small Bold";
    }
  }

  .image {
    display: block;
    margin: 32px auto;
    width: 100%;
    max-width: 344px;
  }

  .list {
    margin-top: 8px;

    .listItem {
      font-family: "New York Small";
      font-size: 16px;
      line-height: 32px;
      margin-left: 24px;
      position: relative;

      &:after {
        content: ".";
        display: block;
        position: absolute;
        font-size: 36px;
        top: 6px;
        line-height: 0px;
        left: -18px;
      }
    }
  }

  .notification {
    font-family: "New York Small";
    background-color: #fff;
    padding: 12px 26px;
    border-radius: 8px;
    margin-top: 24px;
    line-height: 22px;
    font-size: 18px;
    color: #2b3330;

    b {
      font-family: "New York Small Bold";
    }
  }
}
