.wrapper {
  width: 100%;
  min-height: 100dvh;
}

.plansBg {
  background-color: #f4f4c2;
  padding-bottom: 60px;
}

.plansBgWhite {
  background-color: #ffffff;
  padding-bottom: 60px;
}

.paymentSuccessBg {
  background-color: #141d19;
}

.questionnaireBg {
  background-color: #cbdcb5;

  .layout {
    padding: 0px 16px 16px 16px;
  }
}

.questionnaireWhiteBg {
  background-color: #f1ece9;

  .layout {
    padding: 0px 16px 16px 16px;
  }
}

.presaleGuideBg {
  background-color: #f1ece9;
  .layout {
    padding: 16px 16px 46px 16px;
  }
}

.layout {
  max-width: 724px;
  margin: auto;
  padding: 16px;
}

.cancel {
  max-width: 724px;
  margin: auto;
}

.cancelPromo {
  .layout {
    padding: 0px;
  }
}

.cancelOfferAccepted {
  background-color: #141d19;
}
