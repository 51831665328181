@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  background-color: #ffffda;
  height: 3px;

  &.isWhite {
    background-color: #e5e5e5;
  }

  .filled {
    height: 100%;
    background-color: #2b3330;
    transition: 0.3s all;
  }
}
