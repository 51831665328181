.wrapper {
  font-family: "New York Small";
  background-color: #fff;
  padding: 32px 16px;
  border-radius: 8px;
  margin-top: 24px;
  width: calc(100% + 32px);
  margin-left: -16px;

  .bannerImg {
    float: right;
  }
}

.point {
  margin: 18px 0px;
  display: flex;

  &:first-child {
    margin: 0px;
  }

  .icon {
    min-width: 8px;
    max-height: 8px;
    margin-top: 4px;

    svg {
      fill: #ff6d03;
    }
  }

  .text {
    margin-left: 8px;

    .title {
      font-family: "New York Small Bold";
      font-size: 16px;
      line-height: 19px;
      color: #2b3330;
    }

    .description {
      font-family: "New York Small";
      font-size: 16px;
      line-height: 19px;
      color: #2b3330;
    }
  }
}
