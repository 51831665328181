.block {
  width: 100%;
  background-color: #ffffda;
  padding: clamp(20px, 5%, 30px) clamp(15px, 5%, 25px);
  border-radius: clamp(8px, 2vw, 16px);
}

.image {
  width: 100%;
  margin-bottom: 20px;
}

.title {
  font-family: New York Small;
  font-size: clamp(22px, 3vw, 30px);
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2b3330;
}

.describtion {
  font-family: New York Small;
  font-size: clamp(16px, 3vw, 22px);
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #565c59;
}

.listItem {
  font-family: New York Small;
  font-size: clamp(16px, 3vw, 22px);
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #565c59;
  display: flex;
  margin-top: 10px;
}
.icon {
  border: 1px solid #ff6d03;
  width: 12px;
  display: block;
  height: 0;
  margin: 10px 20px 10px 5px;
  border-radius: 1px;
}
