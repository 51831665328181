@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;

  &.isWhite {
    .question {
      background-color: white;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
    }
  }

  .question {
    width: 100%;
    padding: 16px 22px;
    background-color: #f4f4c2;
    font-family:
      New York Small,
      "Times New Roman",
      serif;
    font-style: normal;
    cursor: pointer;
    min-height: 72px;
    border-radius: 4px;
    margin: 12px 0px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.5px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    -o-tap-highlight-color: transparent;
    tap-highlight-color: transparent;

    .text {
      margin-right: 8px;
    }

    &:focus {
      outline: 0;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      -moz-tap-highlight-color: transparent;
      -o-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
    }

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .icon {
      transform: rotate(180deg);
      overflow: hidden;
      path {
        fill: #141d19;
      }
    }
  }
}
