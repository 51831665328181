.block {
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.iconContainer {
  width: 100%;
  padding: 21px;
  background-color: #ffffda;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.title {
  font-family: "New York Small";
  font-size: clamp(16px, 3vw, 22px);
  line-height: 1.2;
  letter-spacing: -0.02em;
  white-space: pre-line;
  text-align: center;
  color: #2b3330;
  margin-top: 4px;
}

@media screen and (max-width: 550px) {
  .block {
    width: 50%;
  }
}
