.wrapper {
  margin-top: 24px;
  width: 100%;

  .card {
    padding: 26px;
    border-radius: 10px;
    background: linear-gradient(
      180.28deg,
      #2b3330 8.66%,
      #37423d 51.02%,
      #141d19 99.75%
    );
    position: relative;

    .title {
      width: calc(100% - 150px);
      font-family: "New York Small";
      color: #f3f4f4;
      font-size: 22px;
      line-height: 24px;
      position: relative;
      z-index: 2;
    }

    .prices {
      margin-top: 16px;
      font-family: "New York Small";
      color: #f3f4f4;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      .priceOld {
        font-size: 26px;
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 40px;
          top: 10px;
          left: 0px;
          height: 3px;
          background-color: #e41723;
          transform: rotate(-25deg);
        }
      }

      .priceNew {
        margin-left: 18px;
        font-size: 32px;
      }
    }

    .reviews {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      display: flex;
      gap: 8px;

      .starReviewText {
        color: #8e9391;
        font-size: 14px;
        line-height: 14px;
        margin-top: 4px;
      }

      .starReviewStars {
        margin-top: 4px;
        display: flex;
        gap: 2px;

        svg {
          fill: #ffa621;
        }
      }
    }

    .book {
      background-image: url("./../../../assets//images/only_book.png");
      position: absolute;
      width: 146px;
      height: 154px;
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
      z-index: 0;
    }
  }

  .expre {
    background-color: #fed33b;
    padding: 16px 16px 32px 16px;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .expireText {
      font-family: "SF Pro Display";
      font-size: 14px;
      line-height: 15px;
      color: #2b3330;

      b {
        display: 800;
      }
    }

    .timer {
      padding: 8px;
      background-color: #ffffff;
      border-radius: 6px;
      font-family: "Druk Cy Web";
      font-size: 34px;
      color: #2b3330;
    }

    .gift {
      width: 61px;
      height: 61px;
      background-image: url("./../../../assets/gift.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
