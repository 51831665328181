@import "./../../../assets/variables/variables.scss";

.wrapper {
  width: 100%;
  margin-top: 32px;

  .container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffe9;
    gap: 4px;

    border-radius: 8px 8px 0px 0px;
    margin-bottom: 4px;
    border: 1px solid #ffffe9;
    border-bottom: 3px solid #ff6d03;

    .phoneContainer {
      position: relative;

      input {
        padding-left: 16px;
      }

      .plus {
        left: 0px;
        position: absolute;
        font-size: 18px;
        width: 14px;
        height: 12px;
        text-align: center;
        line-height: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:has(:focus) {
      background-color: #ffffff;

      .icon {
        display: flex;
      }
    }
    &:has(:disabled) {
      background-color: #f8f8d2;
      border: 1px solid #f8f8d2;

      .label {
        color: #d2d4d3;
      }
      .input {
        color: #d2d4d3;
      }
    }
    &:has([aria-invalid="true"]) {
      border: 1px solid #ff4003;
      .label {
        color: #ff4003;
      }
      .input {
        color: #ff4003;
      }
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }

  .input {
    background: none;
    border: none;
    outline: none;
    font-size: clamp(16px, 2vw, 20px);
    padding: 18px 12px;
    font-family: "New York Small", "Times New Roman", serif;
  }
}

.privacy {
  display: flex;
  align-items: center;
  margin-top: 24px;

  svg {
    margin-right: 6px;
    fill: #696e6c;
  }

  span {
    color: #696e6c;
    font-size: 10px;
    text-transform: uppercase;
    font-family: "SF-Pro-Text", "Arial", sans-serif;
    letter-spacing: 0.1em;
    line-height: 1.2;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    color: #000000;
  }
}

.btn {
  width: 100%;
  background-color: #2b3330;
  text-align: center;
  color: #ffffff;
  font-family: "SF-Pro-Text", "Arial", sans-serif;
  padding: 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  height: 54px;
  cursor: pointer;
  margin-top: 12px;

  &[data-disabled="true"] {
    opacity: 0.3;
  }
}

.error {
  color: #ff4003;
  font-family: "New York Small";
  font-size: clamp(14px, 1.8vw, 18px);
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 4px;
}
