@import "./../../../assets/variables/variables.scss";

.background {
  max-width: 735px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 16px;
}

h2 {
  color: $titleAndSubTitle-color;
  font-family: "New York Small", "Times New Roman", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.25;
  text-align: center;
  margin-top: 12px;
}

.getMyPlan {
  width: calc(100% - 32px);
  max-width: 692px;
  border: none;
  cursor: pointer;
  color: white;
  box-shadow: -5px 4px 36px 25px #f4f4c2;
  background-color: #ff6d03;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: "SF-Pro-Text", "Arial", sans-serif;
  letter-spacing: 0.06em;
  font-size: 14px;
  min-height: 56px;
  position: fixed;
  bottom: 1%;

  &.isWhite {
    box-shadow: none !important;
    background-color: #208357;
  }
}

.discount {
  text-align: center;

  .discountText {
    font-size: 54px;
    font-family: "Druk Cy Web", "Arial", sans-serif;
    color: #2b3330;
    text-transform: uppercase;
    letter-spacing: 0.54px;
    line-height: 54px;
    transform: rotate(-3.835deg);
  }
}
