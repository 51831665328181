.wrapper {
  background-color: #fff;
  padding: 28px 16px;
  border-radius: 8px;
  margin-top: 24px;
  line-height: 16px;
  font-size: 14px;

  .title {
    font-family: "Druk Cy Web";
    font-size: 33px;
    line-height: 32px;
    color: #2b3330;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }

  .description {
    font-family: "New York Small";
    margin-top: 18px;
    font-weight: 400;
    line-height: 22px;
    font-size: 18px;
    color: #2b3330;
  }

  .images {
    margin-top: 12px;
    position: relative;
    height: 100px;

    .image {
      width: 24%;
      aspect-ratio: 1;
      border: 4px solid #ffffff;
      border-radius: 100%;
      position: absolute;
      top: 0px;

      &.image1 {
        background-image: url("./../../../assets/images/presaleGuide_person1.png");
        background-position: center;
        background-size: cover;
      }

      &.image2 {
        margin-left: 19%;
        z-index: 2;
        background-image: url("./../../../assets/images/presaleGuide_person2.png");
        background-position: center;
        background-size: cover;
        background-color: #c1c0c5;
      }
      &.image3 {
        margin-left: 38%;
        z-index: 2;
        background-image: url("./../../../assets/images/presaleGuide_person3.png");
        background-position: center;
        background-size: cover;
        background-color: #c1c0c5;
      }
      &.image4 {
        margin-left: 57%;
        z-index: 2;
        background-image: url("./../../../assets/images/presaleGuide_person4.png");
        background-position: center;
        background-size: cover;
        background-color: #c1c0c5;
      }
      &.image5 {
        margin-left: 76%;
        z-index: 2;
        background-image: url("./../../../assets/images/presaleGuide_person5.png");
        background-position: center;
        background-size: cover;
        background-color: #c1c0c5;
      }
    }
  }
}
