@import "./../../../assets/variables/variables.scss";

.wrapper {
  .profile {
    display: flex;
    align-items: center;
    font-family: "New York Small";
    font-size: 16px;
    line-height: 18px;
    padding: 12px 0px 24px 0px;
    .text {
      margin-left: 8px;
    }
  }

  .title {
    font-family: "New York Small Bold";
    font-size: 24px;
    line-height: 24px;
  }

  .subtitle {
    font-family: "New York Small Bold";
    font-size: 18px;
    line-height: 20px;
  }

  .content {
    margin-top: 24px;
    font-family: "New York Small";
    font-size: 16px;
    line-height: 18px;
  }
}
