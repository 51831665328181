.wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #f4f4c2;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  justify-content: center;

  .container {
    max-width: 735px;
    width: 100%;
    position: relative;

    span {
      position: absolute;
      right: 2px;
      top: 8px;
      display: inline-block;
      cursor: pointer;
    }

    .menu {
      display: flex;
      flex-direction: column;
      margin-top: 83px;
      width: 100%;

      a {
        font-family: "Roboto", "Arial", sans-serif;
        font-size: 20px;
        text-decoration: none;
        color: #141d19;
      }

      .menulist {
        position: relative;

        li {
          cursor: pointer;
          padding: 16px 0 20px 0;

          a {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.paymentWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #141d19;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  justify-content: center;

  .container {
    max-width: 735px;
    width: 100%;
    position: relative;
    display: flex;

    span {
      position: absolute;
      right: 2px;
      top: 8px;
      cursor: pointer;
    }

    .menu {
      display: flex;
      flex-direction: column;
      margin-top: 63px;
      width: 100%;

      a {
        font-family: "Roboto", "Arial", sans-serif;
        font-size: 20px;
        text-decoration: none;
        color: white;
      }

      .menulist {
        position: relative;

        li {
          cursor: pointer;
          padding: 16px 0 20px 0;

          a {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
