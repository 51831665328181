.results {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content {
    position: relative;
    display: flex;
    padding: 20px 20px 35px;
    background-color: #ffffda;
    border-radius: 4px;
    overflow: hidden;

    &.isWhite {
      background-color: #f3f4f4 !important;
    }

    .percentBlock {
      display: flex;
      align-items: center;
      gap: 4%;
      position: relative;

      span {
        margin: 0 0 0 16%;
        text-shadow:
          1px 1px 0px #ffffff,
          -1px -1px 0px #ffffff;
      }

      p {
        font-family: "New York Small", "Times New Roman", serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.25;
        width: 100%;
        color: inherit;
      }
    }

    svg {
      width: auto;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .percent {
      display: flex;
      flex-direction: row;

      span {
        font-size: 0.6em;
        margin-top: -0.15em;
      }
    }

    span {
      font-family: "Druk Cy Web", "Arial", sans-serif;
      font-size: 54px;
      line-height: 1;
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }
  }
}
