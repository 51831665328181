.block {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 34px;
  padding-top: 20px;
  border-bottom: 1px solid #cbdcb5;
  gap: 12px;
}
.left {
  flex-direction: row-reverse;
}
.right {
  flex-direction: row;
}

.title {
  font-family: "New York Small";
  font-size: clamp(18px, 3vw, 22px);
  line-height: 1;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141d19;
}

.describtion {
  font-family: "New York Small";
  font-size: clamp(16px, 1.5vw, 20px);
  line-height: 19px;
  text-align: left;
  color: #565c59;
  margin-top: 6px;
}

.image {
  width: clamp(110px, 30%, 220px);
  height: clamp(140px, auto, 280px);
  object-fit: contain;
}
