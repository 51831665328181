.discountBlock {
  background-color: #ffa621;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  padding: 10px 10px;

  &.isWhite {
    background-color: #208357;
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  p {
    font-family: "SF-Pro-Text";
    font-size: 12px;
    letter-spacing: 0.2em;
    line-height: 1.33;
    text-transform: uppercase;
  }
}
