.paymentSuccessWrapper {
  width: 100%;
  height: 100vh;
  background-color: #141d19;
  position: relative;
  top: 0;
  left: 0;
  opacity: 0.9;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .error {
    color: #e53935;
  }

  .paymentSuccessContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding-bottom: clamp(25px, 12vw, 60px);
    padding-top: clamp(25px, 7vw, 40px);
  }

  .backgroundSvg {
    position: absolute;
    top: -50px;
    opacity: 0.03;
    height: 100%;
    z-index: -1;
  }

  .checked {
    border: 2px solid green;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
  }
  .enterYourEmail {
    font-family: "New York Small";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.22;
    color: #d2d4d3;
    text-align: center;
  }

  .emailSend {
    display: flex;
    gap: 8px;
    width: 100%;
    max-width: 340px;

    span {
      font-family: "SF-Pro-Text";
      font-style: normal;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
    }

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 3px;
      background-color: #f3f4f4;
      border: 2px solid #f3f4f4;
      border-radius: 4px;
      font-weight: 700;
      padding: 9px 12px;
      caret-color: #ff6d03;
      color: #2b3330;

      input {
        border: none;
        outline: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-family: "New York Small";
        background-color: transparent;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
      }
    }

    .sendBtn {
      color: white;
      background-color: #ff6d03;
      outline: none;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.84px;
      padding: 15px 18px;
      cursor: pointer;
      border: 2px solid #ff6d03;
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;

      &:disabled {
        opacity: 0.4;
      }
    }

    .doneBtn {
      color: #208357;
      background-color: white;
      outline: none;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.84px;
      padding: 15px 18px;
      cursor: pointer;
      border: 2px solid #208357;
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
    }
  }

  .disabledDownloadBlock {
    opacity: 0.2;
    font-family: "New York Small";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.22;
    margin: 0 8px auto;
    display: flex;
    flex-direction: column;
    max-width: 735px;
    align-items: center;
    gap: 27px;

    .downloadLink {
      text-align: center;
      color: #afb1b0;

      a {
        color: #ff6d03;
        text-decoration: underline;
      }
    }

    button {
      border-radius: 4px;
      text-transform: uppercase;
      color: black;
      background-color: white;
      outline: 0;
      border: none;
      font-family: "SF-Pro-Text";
      letter-spacing: 0.06em;
      font-size: 14px;
      min-height: 56px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      outline: none;
      width: 100%;
      min-height: 56px;
    }
  }

  .downloadBlock {
    font-family: "New York Small";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.22;
    margin: 0 8px auto;
    display: flex;
    flex-direction: column;
    max-width: 735px;
    align-items: center;
    gap: 27px;

    .downloadTooltip {
      color: #afb1b0;
      display: flex;
      align-items: flex-start;
      padding: 14px 16px;
      background-color: #2b3330;
      border-radius: 4px;
      position: relative;
      font-family: "New York Small";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.22;

      .exp_icon {
        width: 32px;
        height: 35px;
        margin-left: -4px;
      }

      svg {
        width: 32px;
        height: 35px;
      }

      & > ::before {
        content: "";
        position: absolute;
        left: 35px;
        bottom: -7px;
        width: 17px;
        height: 17px;
        background-color: #2b3330;
        transform: rotate(60deg) skew(27deg);
      }

      span {
        color: #ffa621;
        display: inline;
      }
    }

    .downloadLink {
      text-align: center;
      color: #afb1b0;

      a {
        color: #ff6d03;
        text-decoration: underline;
      }
    }

    button {
      border-radius: 4px;
      text-transform: uppercase;
      color: white;
      background-color: #ff6d03;
      outline: 0;
      border: none;
      cursor: pointer;
      font-family: "SF-Pro-Text";
      letter-spacing: 0.06em;
      font-size: 14px;
      min-height: 56px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      outline: none;
      width: 100%;
      min-height: 56px;
      position: relative;
    }
  }

  .support {
    text-align: center;
    color: #b4b7b5;
    font-family: "New York Small", "Times New Roman", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.22;
    a {
      color: #b4b7b5;
    }
  }
}
