.moneyBeck {
  background-color: #ffffda;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  gap: 16px;

  &.isWhite {
    background-color: #f3f4f4;
  }

  .header {
    display: flex;
    gap: 20px;

    h2 {
      text-align: left;
      align-self: flex-end;
    }
  }

  p {
    font-family: "New York Small";
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
  }
}
