@import "./../../../assets/variables/variables.scss";

.title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  h1 {
    line-height: 1;
    font-family: "Druk Cy Web";
    font-size: 54px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $titleAndSubTitle-color;
  }
}
