.wrapper {
  width: 100%;
  margin-top: 24px;

  .warning {
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    color: #2b3330;
    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
    margin-bottom: 8px;
  }

  .buyBtn {
    width: 100%;
    background-color: #ff6d03;
    border-radius: 8px;
    border: none;
    font-family: "SF Pro Display";
    font-size: 18px;
    letter-spacing: 0.06em;
    text-align: center;
    padding: 16px;
    color: #fff;
    cursor: pointer;
  }

  .skipBtn {
    margin-top: 18px;
    color: #2b3330;
    font-size: 12px;
    text-transform: uppercase;
    font-family: "SF Pro Display";
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
}
