.content {
  background-color: #f4f4c2;
  padding-top: 24px;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  section {
    width: 100%;
    max-width: 724px;
    margin: auto;
    padding: 16px;
  }
}

.logo {
  margin: 0 auto;
  display: block;
  width: clamp(62px, 15%, 120px);
}

.subTitle {
  font-family: "Druk Cy Web";
  font-size: clamp(44px, 10vw, 60px);
  letter-spacing: 0.01em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: pre-line;
  max-width: 700px;
  margin: 0 auto;
  color: #2b3330;
}

.description {
  font-family: "New York Small";
  font-size: clamp(18px, 3vw, 24px);
  letter-spacing: -0.02em;
  text-align: center;
  color: #565c59;
  width: 70%;
  font-weight: 600;
  margin: clamp(14px, 2vw, 20px) auto;
}
form {
  width: 100%;
}
.primery {
  width: 100%;
  background-color: #ff6d03;
  border-radius: 4px;
  border: none;
  font-family: "SF Pro Display";
  font-size: clamp(14px, 2vw, 20px);
  letter-spacing: 0.06em;
  text-align: center;
  padding: 20px;
  color: #fff;
  margin-block: 32px;
  cursor: pointer;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.3;
  }
}
.inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 !important;
  gap: 4px !important;
}
.container {
  width: 100%;
}
.fullName {
  margin-bottom: 16px;
}
.state {
  width: calc(60% - 8px);
}
.zip {
  width: 40%;
}
.phone {
  margin-block: 16px;
}

.paragraph {
  font-family: "SF Pro Display";
  font-size: clamp(10px, 1.5vw, 14px);
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-align: center;
  color: #8e9391;
  text-transform: uppercase;
  margin-block: 35px 15px;
}

.closeIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  font-size: 24px;
}
